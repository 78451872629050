/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/swiper@10.2.0/swiper-element-bundle.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/@studio-freight/lenis@1.0.29/bundled/lenis.min.js
 * - /npm/aos@3.0.0-beta.6/dist/aos.min.js
 * - /npm/gsap@3.12.5/dist/gsap.min.js
 * - /npm/gsap@3.12.5/dist/ScrollTrigger.min.js
 * - /npm/fittext.js@1.2.0/jquery.fittext.min.js
 * - /npm/inputmask@5.0.8/dist/inputmask.min.js
 * - /npm/swiper@10.3.1/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
